export default {
  SET_READY: 'SET_READY',
  SET_PROGRESS: 'SET_PROGRESS',
  BATCH_ACTIONS: 'BATCH_ACTIONS',
  SET_WINDOW_SIZE: 'SET_WINDOW_SIZE',
  SET_LAYOUT: 'SET_LAYOUT',
  SET_IS_MOBILE_MENU_OPEN: 'SET_IS_MOBILE_MENU_OPEN',
  SET_PREV_ROUTE: 'SET_PREV_ROUTE',
  HOME_LOADED: 'HOME_LOADED',
  GALLERY_LOADED: 'GALLERY_LOADED',
  ASSET_LOADED: 'ASSET_LOADED',
  ASSET_PREVIEW_STATE: 'ASSET_PREVIEW_STATE',
  ASSET_DATA: 'ASSET_DATA',
  MINE_STATE: 'MINE_STATE',
  MINE_STATE_LEVEL_UP: 'MINE_STATE_LEVEL_UP',
  MINE_STATE_ONBOARDING: 'MINE_STATE_ONBOARDING',
  LOGIN_STATE: 'LOGIN_STATE'
};
